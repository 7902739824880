import React from 'react';

const Programs = () => {
  return (
    <div className="programs">
      <h2>Programs</h2>
      <p>Explore our various academic programs and their benefits.</p>
    </div>
  );
};

export default Programs;
