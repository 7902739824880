import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Home from './components/Home';
import About from './components/About';
import Courses from './components/Courses';
import Services from './components/Services';
import Programs from './components/Programs';
import Facilities from './components/Facilities';
import Contact from './components/Contact';
import Gallary from './components/Gallary';
import BBA from './components/Courses/BBA';
import BCA from './components/Courses/BCA';
import Pharmacy from './components/Courses/Pharmacy';
import Consulting from './components/Services/Consulting';
import Support from './components/Services/Support';
import Training from './components/Services/Training';
import Library from './components/Facility/Library'; // Import Library Component
import ComputerLabs from './components/Facility/ComputerLab'; // Import Computer Labs Component
import ChemistryLab from './components/Facility/ChemistryLab'; // Import Chemistry Lab Component
import BusFacility from './components/Facility/Bus'; // Import Bus Facility Component
import Hostel from './components/Facility/Hostel'; // Import Hostel Component
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Footer from './components/Footer';

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <div style={{ minHeight: '80vh' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/courses/bba" element={<BBA />} />
            <Route path="/courses/bca" element={<BCA />} />
            <Route path="/courses/pharmacy" element={<Pharmacy />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/consulting" element={<Consulting />} />
            <Route path="/services/support" element={<Support />} />
            <Route path="/services/training" element={<Training />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/gallary" element={<Gallary />} />
            <Route path="/facilities" element={<Facilities />} />
            {/* New Routes for Facilities */}
            <Route path="/facilities/library" element={<Library />} />
            <Route path="/facilities/labs" element={<ComputerLabs />} />
            <Route path="/facilities/chemistry" element={<ChemistryLab />} />
            <Route path="/facilities/bus-facility" element={<BusFacility />} />
            <Route path="/facilities/hostel" element={<Hostel />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </Router>
      <Footer />
    </>
  );
};

export default App;
