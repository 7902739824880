import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Pharmacy.css'; // Custom styles if needed

const Pharmacy = () => {
  return (
    <div className="dpharmacy-course">
      <Container>
        {/* DPharmacy Course Title */}
        <h1 className="text-center mt-5">Diploma in Pharmacy (DPharmacy)</h1>
        <p className="text-center mb-5">Your pathway to a rewarding career in healthcare</p>

        {/* Course Description Section */}
        <section className="course-description">
          <h2>About the DPharmacy Program</h2>
          <p>
            The Diploma in Pharmacy (DPharmacy) program at Kalavati Institute of Management is designed to prepare students for successful careers in the pharmaceutical industry. 
            Our curriculum includes comprehensive training in pharmaceutical sciences, healthcare, and patient care.
          </p>
          <p>
            Students will gain hands-on experience in pharmacy practice, including drug formulation, dosage administration, and pharmacy management. 
            The program is focused on fostering essential skills required to excel in the healthcare sector.
          </p>
        </section>

        {/* Faculty Section */}
        <section className="faculty mt-5">
          <h2>Meet Our Faculty</h2>
          <Row className="mt-4">
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/150?text=Faculty+1"
                alt="Faculty 1"
                className="img-fluid faculty-photo"
              />
              <h3>Dr. Emily White</h3>
              <p>Head of Pharmacy Department</p>
            </Col>
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/150?text=Faculty+2"
                alt="Faculty 2"
                className="img-fluid faculty-photo"
              />
              <h3>Prof. John Smith</h3>
              <p>Senior Lecturer</p>
            </Col>
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/150?text=Faculty+3"
                alt="Faculty 3"
                className="img-fluid faculty-photo"
              />
              <h3>Dr. Linda Johnson</h3>
              <p>Associate Professor</p>
            </Col>
          </Row>
        </section>

        {/* Facilities Section */}
        <section className="facilities mt-5">
          <h2>Facilities</h2>
          <p>
            Our institute provides modern facilities to enhance the learning experience of DPharmacy students. 
            This includes state-of-the-art chemistry labs, a well-stocked department library, and dedicated study spaces.
          </p>
          <Row className="mt-4">
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/400x300?text=Chemistry+Lab"
                alt="Chemistry Lab"
                className="img-fluid facility-photo"
              />
              <h4>Chemistry Lab</h4>
            </Col>
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/400x300?text=Library"
                alt="Department Library"
                className="img-fluid facility-photo"
              />
              <h4>Department Library</h4>
            </Col>
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/400x300?text=Office"
                alt="Administrative Office"
                className="img-fluid facility-photo"
              />
              <h4>Administrative Office</h4>
            </Col>
          </Row>
        </section>

        {/* HOD Section */}
        <section className="hod mt-5">
          <h2>Head of Department</h2>
          <Row className="mt-4">
            <Col className="text-center">
              <img
                src="https://via.placeholder.com/200?text=HOD"
                alt="HOD"
                className="img-fluid hod-photo"
              />
              <h3>Dr. Emily White</h3>
              <p>Head of Pharmacy Department</p>
              <p>
                Dr. Emily White has extensive experience in pharmaceutical sciences and is dedicated to providing students with the highest quality education in pharmacy practices.
              </p>
            </Col>
          </Row>
        </section>

        {/* Student Section */}
        <section className="students mt-5">
          <h2>Our Students</h2>
          <Row className="mt-4">
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/150?text=Student+1"
                alt="Student 1"
                className="img-fluid student-photo"
              />
              <h4>Anna Taylor</h4>
              <p>Current DPharmacy Student</p>
            </Col>
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/150?text=Student+2"
                alt="Student 2"
                className="img-fluid student-photo"
              />
              <h4>Mark Davis</h4>
              <p>Current DPharmacy Student</p>
            </Col>
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/150?text=Student+3"
                alt="Student 3"
                className="img-fluid student-photo"
              />
              <h4>Lisa Brown</h4>
              <p>Current DPharmacy Student</p>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default Pharmacy;
