// src/components/Bus.js
import React from 'react';
import busImage from '../../images/bus.png';
import busSafetyImage from '../../images/bus2.png'; // Additional image for safety features
import busComfortImage from '../../images/bus3.jpeg'; // Additional image for comfort features
import './Bus.css'; // Import CSS for specific styles

const Bus = () => {
    return (
        <div className="facility bus-facility">
            <h2>Bus Facility</h2>
            <img src={busImage} alt="Bus Facility" className="main-image" />
            <ul className="facility-list">
                <li>
                    <strong>Comprehensive Coverage:</strong>
                    <span> Bus routes cover major city areas, ensuring accessibility for all students.</span>
                </li>
                <li>
                    <strong>Safety & Comfort:</strong>
                    <span> Equipped with GPS tracking and modern safety features for a secure journey.</span>
                    <img src={busSafetyImage} alt="Safety Features" className="additional-image" />
                    <img src={busComfortImage} alt="Comfort Features" className="additional-image" />
                </li>
                <li>
                    <strong>Fixed Timings:</strong>
                    <span> Scheduled multiple trips daily for student convenience and time management.</span>
                </li>
                <li>
                    <strong>Affordable Fees:</strong>
                    <span> Low-cost transportation options with discounts for long-distance students.</span>
                </li>
                <li>
                    <strong>Environment-Friendly:</strong>
                    <span> Eco-friendly buses are part of our sustainable approach to transportation.</span>
                </li>
                <li>
                    <strong>Online Tracking:</strong>
                    <span> Students can track bus locations in real-time via a mobile app.</span>
                </li>
                <li>
                    <strong>Priority Seating:</strong>
                    <span> Reserved seating for senior students and those with special needs.</span>
                </li>
            </ul>
        </div>
    );
};

export default Bus;
