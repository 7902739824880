import React from 'react';

const Facilities = () => {
  return (
    <div className="facilities">
      <h2>Facilities</h2>
      <p>Our campus offers various facilities for students.</p>
    </div>
  );
};

export default Facilities;
