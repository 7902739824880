import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import SupportImg from '../../images/support.png';
import './Support.css'; // For custom styles

const Support = () => {
  return (
    <Container className="service-container">
      <Row className="align-items-center">
        <Col md={6}>
          <Image src={SupportImg} alt="Support Service" fluid className="service-img" />
        </Col>
        <Col md={6}>
          <h2>Support Services</h2>
          <p>
            Our dedicated support team is here to assist you with any challenges that arise in your academic or operational activities. 
            Whether it's technical issues, logistical problems, or administrative support, we provide ongoing assistance to ensure your 
            success and smooth operation.
          </p>
          <h4>Key Areas of Support:</h4>
          <ul>
            <li>Technical Assistance</li>
            <li>Operational Support</li>
            <li>Administrative Guidance</li>
            <li>Round-the-Clock Availability</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Support;
