import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './NavigationBar.css'; // Custom styles

const NavigationBar = () => {
  return (
    <Navbar collapseOnSelect expand="lg" variant="dark" sticky="top" className="custom-navbar">
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" style={{color:'white'}}>
          <Nav className="ml-auto" style={{color:'white'}}>
            <NavLink exact to="/" className="nav-link" activeClassName="active">
              Home
            </NavLink>
            <NavLink exact to="/about" className="nav-link" activeClassName="active">
              About
            </NavLink>

            {/* Courses Dropdown */}
            <NavDropdown title="Courses" id="courses-dropdown" className="custom-dropdown">
              <NavDropdown.Item as={NavLink} to="/courses/bba">
                BBA
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/courses/bca">
                BCA
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/courses/pharmacy">
                D.Pharmacy
              </NavDropdown.Item>
            </NavDropdown>

            {/* Services Dropdown */}
            <NavDropdown title="Services" id="services-dropdown" className="custom-dropdown">
              <NavDropdown.Item as={NavLink} to="/services/consulting">
                Consulting
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/services/support">
                Support
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/services/training">
                Training
              </NavDropdown.Item>
            </NavDropdown>

            {/* Programs Dropdown */}
            <NavDropdown title="Programs" id="programs-dropdown" className="custom-dropdown">
              <NavDropdown.Item as={NavLink} to="/programs/internship">
                Internship
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/programs/events">
                Events
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/programs/news">
                News
              </NavDropdown.Item>
            </NavDropdown>

            {/* Facilities Dropdown */}
            <NavDropdown title="Facilities" id="facilities-dropdown" className="custom-dropdown">
              <NavDropdown.Item as={NavLink} to="/facilities/library">
                Library
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/facilities/labs">
                Computer Labs
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/facilities/chemistry">
                Chemistry Lab
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/facilities/bus-facility">
                Bus Facility
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/facilities/hostel">
                Hostel
              </NavDropdown.Item>
            </NavDropdown>

            <NavLink to="/gallary" className="nav-link" activeClassName="active">
              Gallary
            </NavLink>
            <NavLink to="/contact" className="nav-link" activeClassName="active">
              Contacts
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
