
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './BCA.css'; // Custom styles if needed
import BCA1 from '../../images/bcaaa.png';
import BCA2 from '../../images/bca5.png';
import BCA3 from '../../images/administrative.jpeg';
import BCA4 from '../../images/library2.jpeg';

const BCA = () => {
  return (
    <div className="bca-course">
      <Container>
        {/* BCA Course Title */}
        <h1 className="text-center mt-5">Bachelor of Computer Applications (BCA)</h1>
        <p className="text-center mb-5">Empowering future tech leaders</p>

        {/* Course Description Section */}
        <section className="course-description">
          <h2>About the BCA Program</h2>
          <p>
            The Bachelor of Computer Applications (BCA) program at Kalavati Institute of Management is designed to equip students with essential skills in computer science and application development.
            Our curriculum focuses on both theoretical foundations and practical applications, preparing students for successful careers in the IT industry.
          </p>
          <p>
            Students will gain in-depth knowledge of programming languages, software development, database management, and web technologies.
            With hands-on experience through projects and workshops, graduates will be well-prepared to tackle real-world challenges in technology.
          </p>
        </section>

        {/* Faculty Section */}
        <section className="faculty mt-5">
          <h2>Meet Our Faculty</h2>
          <Row className="mt-4">
            <Col md={4} className="text-center">
              <img
                src=''
                alt="Faculty 1"
                className="img-fluid faculty-photo"
              />
              <h3>Dr. Alice Brown</h3>
              <p>Head of BCA Department</p>
            </Col>
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/150?text=Faculty+2"
                alt="Faculty 2"
                className="img-fluid faculty-photo"
              />
              <h3>Prof. Michael Lee</h3>
              <p>Senior Lecturer</p>
            </Col>
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/150?text=Faculty+3"
                alt="Faculty 3"
                className="img-fluid faculty-photo"
              />
              <h3>Dr. Sarah Wilson</h3>
              <p>Associate Professor</p>
            </Col>
          </Row>
        </section>

        {/* Facilities Section */}
        <section className="facilities mt-5">
          <h2>Facilities</h2>
          <p>
            Our institute offers modern facilities to enhance the learning experience of BCA students.
            This includes advanced computer labs, a well-stocked library, and dedicated study spaces.
          </p>
          <Row className="mt-4">
            <Col md={4} className="text-center">
              <img
                src={BCA1}
                alt="Computer Lab"
                className="img-fluid facility-photo"
              />
              <h4>Computer Lab</h4>
            </Col>
            <Col md={4} className="text-center">
              <img
                src={BCA4}
                alt="Library"
                height="200px"
                className="img-fluid facility-photo"
              />
              <h4>Library</h4>
            </Col>
            <Col md={4} className="text-center">
              <img
                src={BCA3}
                alt="Office"
                className="img-fluid facility-photo"
              />
              <h4>Administrative Office</h4>
            </Col>
          </Row>
        </section>

        {/* HOD Section */}
        <section className="hod mt-5">
          <h2>Head of Department</h2>
          <Row className="mt-4">
            <Col className="text-center">
              <img
                src="https://via.placeholder.com/200?text=HOD"
                alt="HOD"
                className="img-fluid hod-photo"
              />
              <h3>Dr. Alice Brown</h3>
              <p>Head of BCA Department</p>
              <p>
                Dr. Alice Brown brings years of expertise in computer applications and software engineering to our students.
                Her commitment to education and innovation fosters an engaging learning environment.
              </p>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default BCA;
