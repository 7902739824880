// src/components/Library.js
import React from 'react';
import libraryImage from '../../images/library.jpg';
import bbaResourcesImage from '../../images/library.png'; // Additional image for BBA resources
import bcaResourcesImage from '../../images/library4.jpeg'; // Additional image for BCA resources
import pharmacyResourcesImage from '../../images/library5.jpeg'; // Additional image for Pharmacy resources
import './Library.css'; // Import CSS for specific styles

const Library = () => {
    return (
        <div className="facility library">
            <h2>Library</h2>
            <img src={libraryImage} alt="Library" className="main-image" />
            <ul className="facility-list">
                <li>
                    <strong>Extensive Collection of Books:</strong>
                    <span> Thousands of volumes on various subjects.</span>
                </li>
                <li>
                    <strong>Digital Library Access:</strong>
                    <span> E-books, journals, and online resources available.</span>
                </li>
                <li>
                    <strong>Reading Rooms:</strong>
                    <span> Comfortable spaces for individual and group study.</span>
                </li>
                <li>
                    <strong>Library Management System:</strong>
                    <span> Automated system for book issue and return.</span>
                </li>
                <li>
                    <strong>Research Assistance:</strong>
                    <span> Librarians assist students with research projects.</span>
                </li>
                <li>
                    <strong>BBA Resources:</strong>
                    <span> Specialized books and journals for Business Administration.</span>
                    <img src={bbaResourcesImage} alt="BBA Resources" className="additional-image" />
                </li>
                <li>
                    <strong>BCA Resources:</strong>
                    <span> Comprehensive resources for Computer Applications.</span>
                    <img src={bcaResourcesImage} alt="BCA Resources" className="additional-image" />
                </li>
                <li>
                    <strong>Pharmacy Resources:</strong>
                    <span> Extensive materials on pharmaceutical sciences.</span>
                    <img src={pharmacyResourcesImage} alt="Pharmacy Resources" className="additional-image" />
                </li>
            </ul>
        </div>
    );
};

export default Library;
