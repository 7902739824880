// src/components/Hostel.js
import React from 'react';
import hostelImage from '../../images/hostel.jpeg';
import './Hostel.css'; // Import CSS for specific styles

const Hostel = () => {
    return (
        <div className="facility hostel">
            <h2>Hostel</h2>
            <img src={hostelImage} alt="Hostel" className="main-image" />
            <ul className="facility-list">
                <li>
                    <strong>Comfortable Accommodation:</strong>
                    <span> Separate hostels for boys and girls, equipped with modern amenities.</span>
                </li>
                <li>
                    <strong>Mess & Dining:</strong>
                    <span> Nutritious meals with vegetarian and non-vegetarian options.</span>
                </li>
                <li>
                    <strong>24/7 Security:</strong>
                    <span> Biometric entry and round-the-clock security for student safety.</span>
                </li>
                <li>
                    <strong>Wi-Fi & Study Areas:</strong>
                    <span> High-speed internet access and dedicated study rooms available.</span>
                </li>
                <li>
                    <strong>Recreational Facilities:</strong>
                    <span> Gym, common rooms, and social spaces for relaxation and interaction.</span>
                </li>
                <li>
                    <strong>Events & Activities:</strong>
                    <span> Regular cultural and recreational activities to promote community living.</span>
                </li>
                <li>
                    <strong>Health & Well-being:</strong>
                    <span> Access to healthcare services and counseling support available.</span>
                </li>
            </ul>
        </div>
    );
};

export default Hostel;
