import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './Header.css'; // CSS file for styles
import Logo from '../../images/logo.jpg';
import NavigationBar from './NavigationBar';
import EnquiryForm from '../EnquiryForm'; // Import the enquiry form component

const Header = () => {
  // State to manage the visibility of the enquiry form
  const [showEnquiryForm, setShowEnquiryForm] = useState(false);

  // Function to handle the enquiry button click
  const handleEnquiryClick = () => {
    setShowEnquiryForm(true);
  };

  // Function to handle closing the form
  const handleCloseForm = () => {
    setShowEnquiryForm(false);
  };

  return (
    <header className="header">
      <Container fluid>
        {/* Contact Section */}
        <div className="contact-section">
          <marquee className="marquee">
            <span>Contact Us: 9975596764, 9673667777, 9822277676, 9421661669 | Email: info@kalavaticollege.in</span>
          </marquee>
        </div>

        {/* Logo and College Info Section */}
        <div className="logo-section">
          <img src={Logo} alt="Kalavati Institute Logo" className="logo" />

          {/* Centered College Information */}
          <div className="college-info">
            <p><strong>Kai. Shri Lalbahadur Shastri Memorial Charitable Trust Aurangabad Under</strong></p>
            <h1 className="college-name">Kalavati Institute of Management</h1>
            <h3>BBA & BCA</h3>
            <p>DTE CODE: 02712</p>
            <h1 className="college-name">Kalavati Institute of Pharmacy</h1>
            <h3>D.Pharmacy</h3>
            <span><p>MSBTE CODE: 52366</p></span>
            <p style={{marginTop:'10px',fontSize:'15px'}}>Gut No.240/1 Jatwada Road, Harsul, Chh. Sambhajinagar</p>
          </div>

          {/* Enquiry Button */}
          <div className="enquiry-button">
            <button onClick={handleEnquiryClick} className="btn enquiry-btn" style={{color:'white'}}>Enquiry Now !</button>
          </div>
        </div>
      </Container>
      <NavigationBar />

      {/* Conditionally render the enquiry form */}
      {showEnquiryForm && <EnquiryForm onClose={handleCloseForm} />}
    </header>
  );
};

export default Header;
