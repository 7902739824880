import React from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import './Contact.css'; // Import custom styles

const Contact = () => {
  return (
    <Container className="contact-page">
      <h2 className="text-center my-4">Contact Us</h2>

      {/* Contact Information Box */}
      <Row className="contact-info">
        <Col md={6} className="mb-4">
          <Card className="h-100 shadow-sm p-3">
            <Card.Body>
              <h4>Kalavati Institute of Management</h4>
              <p><strong>Address:</strong> Gut No.240/1 Jatwada Road, Harsul, Chh. Sambhajinagar</p>
              <p><strong>Email:</strong> info@kalavaticollege.in</p>
              <p><strong>Contacts:</strong> 9975596764, 9673667777, 9822277676, 9421661669</p>
            </Card.Body>
          </Card>
        </Col>

        {/* Map Box */}
        <Col md={6}>
          <Card className="h-100 shadow-sm p-3">
            <Card.Body>
              <iframe
                title="Kalavati Institute Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.5944933042475!2d73.8477842!3d18.5204308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf1e2bb5a63d%3A0x4d9099bf8b02a147!2sPune%20University!5e0!3m2!1sen!2sin!4v1638205089743!5m2!1sen!2sin"
                width="100%"
                height="300"
                allowFullScreen=""
                loading="lazy"
                style={{ border: '0', borderRadius: '10px' }}
              ></iframe>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Contact Form Box */}
      <Row className="contact-form mt-5">
        <Col md={8} className="mx-auto">
          <Card className="shadow-sm p-4">
            <Card.Body>
              <h4 className="text-center mb-4">Get in Touch with Us</h4>
              <Form>
                <Form.Group controlId="formName" className="mb-3">
                  <Form.Label>Your Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" required />
                </Form.Group>

                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter your email" required />
                </Form.Group>

                <Form.Group controlId="formMessage" className="mb-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={5} placeholder="Your message" required />
                </Form.Group>

                <div className="text-center">
                  <Button variant="primary" type="submit">
                    Send Message
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
