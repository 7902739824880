import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import TrainingImg from '../../images/training.png'; // Path to your training image
import './Training.css'; // For custom styles

const Training = () => {
  return (
    <Container className="service-container">
      <Row className="align-items-center">
        <Col md={6}>
          <Image src={TrainingImg} alt="Training Service" fluid className="service-img" />
        </Col>
        <Col md={6}>
          <h2>Training Services</h2>
          <p>
            Our comprehensive training programs are designed to empower students and professionals with the skills needed to thrive 
            in their careers. We offer hands-on training in various domains, focusing on practical knowledge that can be applied 
            immediately in the workplace or academic settings.
          </p>
          <h4>Training Areas:</h4>
          <ul>
            <li>Leadership and Management Skills</li>
            <li>Information Technology Training</li>
            <li>Soft Skills Development</li>
            <li>Industry-Specific Certifications</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Training;
