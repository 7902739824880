// src/components/ComputerLab.js
import React from 'react';
import labImage from '../../images/computer1.jpeg';
import labInstructorsImage from '../../images/computer3.jpeg'; // Additional image for instructors
import softwareImage from '../../images/computer2.jpeg'; // Additional image for software updates
import './ComputerLab.css'; // Import CSS for specific styles

const ComputerLab = () => {
    return (
        <div className="facility computer-lab">
            <h2>Computer Lab</h2>
            <img src={labImage} alt="Computer Lab" className="main-image" />
            <ul className="facility-list">
                <li>
                    <strong>State-of-the-Art Systems:</strong>
                    <span> High-performance computers equipped with the latest software.</span>
                </li>
                <li>
                    <strong>High-Speed Internet:</strong>
                    <span> Fast Wi-Fi access available throughout the lab for seamless connectivity.</span>
                </li>
                <li>
                    <strong>Dedicated Lab Instructors:</strong>
                    <span> Support and guidance from qualified lab instructors.</span>
                    <img src={labInstructorsImage} alt="Lab Instructors" className="additional-image" />
                </li>
                <li>
                    <strong>Flexible Timings:</strong>
                    <span> Open beyond regular hours to accommodate project completion and study.</span>
                </li>
                <li>
                    <strong>Regular Software Updates:</strong>
                    <span> Computers are regularly updated with the latest tools and software.</span>
                    <img src={softwareImage} alt="Software Updates" className="additional-image" />
                </li>
                <li>
                    <strong>Group Study Rooms:</strong>
                    <span> Available rooms for group projects and collaborative learning.</span>
                </li>
                <li>
                    <strong>Printing and Scanning:</strong>
                    <span> Facilities for printing and scanning available on-site.</span>
                </li>
            </ul>
        </div>
    );
};

export default ComputerLab;
