import React from 'react';
import './Gallary.css'; // Import custom styles if needed
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap for styling
import Gallary1 from '../images/bca.jpeg';
import Gallary2 from '../images/bca2.jpeg';
import Gallary3 from '../images/bca6.png';
import Gallary4 from '../images/bca4.jpeg';
import Gallary5 from '../images/bba.jpg';
import Gallary6 from '../images/bba7.png';
import Gallary7 from '../images/bba3.jpeg';
import Gallary9 from '../images/pharmacy1.jpg';
import Gallary10 from '../images/pharmacyy.png';
import Gallary11 from '../images/pharmacy8.png';
import Gallary12 from '../images/pharmacy5.png';
import Gallary13 from '../images/bbb.png';

const Gallary = () => {
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-5">Gallery</h2>

      {/* BBA Section */}
      <h3>BBA</h3>
      <div className="row mb-4">
        <div className="col-md-3 mb-4">
          <div className="image-container">
            <img
              src={Gallary5}
              alt="BBA 1"
              className="img-fluid animated"
            />
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="image-container">
            <img
              src={Gallary6}
              alt="BBA 2"
              className="img-fluid animated"
            />
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="image-container">
            <img
              src={Gallary7}
              alt="BBA 3"
              className="img-fluid animated"
            />
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="image-container">
            <img
              src={Gallary13}
              alt="BBA 4"
              className="img-fluid animated"
            />
          </div>
        </div>
      </div>

      {/* BCA Section */}
      <h3>BCA</h3>
      <div className="row mb-4">
        <div className="col-md-3 mb-4">
          <div className="image-container">
            <img
              src={Gallary1}
              alt="BCA 1"
              className="img-fluid animated"
            />
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="image-container">
            <img
              src={Gallary2}
              alt="BCA 2"
              className="img-fluid animated"
            />
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="image-container">
            <img
              src={Gallary3}
              alt="BCA 3"
              className="img-fluid animated"
            />
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="image-container">
            <img
              src={Gallary4}
              alt="BCA 4"
              className="img-fluid animated"
            />
          </div>
        </div>
      </div>

      {/* D.Pharmacy Section */}
      <h3>D.Pharmacy</h3>
      <div className="row mb-4">
        <div className="col-md-3 mb-4">
          <div className="image-container">
            <img
              src={Gallary9}
              alt="D.Pharmacy 1"
              className="img-fluid animated"
            />
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="image-container">
            <img
              src={Gallary10}
              alt="D.Pharmacy 2"
              className="img-fluid animated"
            />
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="image-container">
            <img
              src={Gallary11}
              alt="D.Pharmacy 3"
              className="img-fluid animated"
            />
          </div>
        </div>
        <div className="col-md-3 mb-4">
          <div className="image-container">
            <img
              src={Gallary12}
              alt="D.Pharmacy 4"
              className="img-fluid animated"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallary;
