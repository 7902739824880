import React from 'react';
import './About.css'; // Import custom styles if needed
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap for styling

const About = () => {
  return (
    <div className="about container mt-5">
      <h2 className="text-center mb-5">About Us</h2>

      {/* Section for Kalavati Institute of Pharmacy and Kalavati Institute of Management in one line */}
      <div className="row mb-4">
        <div className="col-md-6">
          <div className="card shadow p-4">
            <h3 className="text-center mb-4">About Kalavati Institute of Pharmacy</h3>
            <p>
              Kalavati Institute of Pharmacy (D.Pharm.) is situated in the lush green outskirts of the great city of Aurangabad. The location blends a pollution-free climate with the modern city life of Aurangabad. The college spans a complex of 5 acres, housed in a spacious building designed with course requirements and student convenience in mind. There are spacious laboratories, classrooms, a professionally designed Animal House, and a huge auditorium. The college is affiliated with Aurangabad University, Government of Maharashtra, and Pharmacy Council of India, New Delhi. The faculty is highly educated and dedicated to the welfare of the students. The library offers all the necessary books and more.
            </p>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card shadow p-4">
            <h3 className="text-center mb-4">About Kalavati Institute of Management</h3>
            <p>
              Kalavati Institute of Management was established as a center for higher education in Aurangabad, Maharashtra. The college has a spacious campus spread over an area of 19.36 acres and is affiliated with Babasaheb Ambedkar Marathwada University, Aurangabad. It is managed by the Marathwada Shikshan Prasarak Mandal, which runs more than 143 educational institutions. The hostel, located on campus, accommodates 105 students and has internet facilities as well as a separate library. The college has several associations like the Literary, Social Science responsible for conducting various extra.
            </p>
          </div>
        </div>
      </div>

      {/* Vision Section */}
      <div className="row mb-4">
        <div className="col-md-6">
          <div className="card shadow p-4">
            <h4 className="text-center">Vision</h4>
            <p>To be a leading institute of excellence in management education and research for budding ethical managerial leaders and entrepreneurs.We provide a conducive environment suitable.</p>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card shadow p-4">
            <h4 className="text-center">Mission</h4>
            <p>We are committed to imparting value-based education to students. Our goal is to develop skills among students to become professionally competent and meet industry and societal demands. </p>
          </div>
        </div>
      </div>

      {/* Quality Policy Section */}
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card shadow p-4">
            <h4 className="text-center">Quality Policy</h4>
            <p>The quality policy of the institute is defined, documented, announced, and declared by the chairman. It is appropriate to the purpose of the organization. It includes a commitment to comply with requirements for continuous improvement of the quality management system. It provides the framework for establishing quality objectives and is understood by all employees, being displayed at various places in the institute. It is reviewed for continuing suitability.</p>
          </div>
        </div>
      </div>

      {/* Why Join Kalavati Institute of Management Section */}
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card shadow p-4">
            <h4 className="text-center">Why Join Kalavati Institute of Management?</h4>
            <p><strong>Pioneering institute defining excellence:</strong> The college has won many awards, including the 'Highest Affiliation Score' from the University and the 'Best College Award for NSS Unit' by the Government of Maharashtra.</p>
            <p><strong>Best in class lab facility:</strong> The college has many equipped labs to carry out research and experiments. In fact, three sponsored projects are currently being researched in the college, sponsored by Dr. Babasaheb Ambedkar Marathwada University, the University Grants Commission, and the Department of Science and Technology, Government of India.</p>
            <p><strong>Encouraging student confidence by organizing events:</strong> Many competitions such as debate, essay, and Best Project Competitions are organized regularly. The college also organizes NSS activities.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
