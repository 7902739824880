import React from 'react';

const Services = () => {
  return (
    <div className="services">
      <h2>Our Services</h2>
      <p>We offer various services to support our students and faculty.</p>
    </div>
  );
};

export default Services;
