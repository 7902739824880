// src/components/ChemistryLab.js
import React from 'react';
import chemistryImage from '../../images/chem1.jpeg';
import labEquipmentImage from '../../images/chem3.jpeg'; // Additional image for lab equipment
import safetyImage from '../../images/chem2.jpeg'; // Additional image for safety measures
import './ChemistryLab.css'; // Import CSS for specific styles

const ChemistryLab = () => {
    return (
        <div className="facility chemistry-lab">
            <h2>Chemistry Lab</h2>
            <img src={chemistryImage} alt="Chemistry Lab" className="main-image" />
            <ul className="facility-list">
                <li>
                    <strong>Modern Equipment:</strong> 
                    <span> Instruments like spectrophotometers, pH meters, and centrifuges enable precise experiments.</span>
                    <img src={labEquipmentImage} alt="Lab Equipment" className="additional-image" />
                </li>
                <li>
                    <strong>Safety Measures:</strong>
                    <span> Strict safety protocols with fire extinguishers, personal protective equipment (PPE), and first aid kits.</span>
                    <img src={safetyImage} alt="Safety Measures" className="additional-image" />
                </li>
                <li>
                    <strong>Experienced Lab Technicians:</strong>
                    <span> Guidance from qualified lab technicians ensures safety and proper handling of chemicals.</span>
                </li>
                <li>
                    <strong>Hands-on Learning:</strong>
                    <span> Practical chemistry experiments are integral to the curriculum, fostering critical thinking.</span>
                </li>
                <li>
                    <strong>Sustainable Practices:</strong>
                    <span> Initiatives to minimize waste and promote green chemistry, focusing on eco-friendly methods.</span>
                </li>
                <li>
                    <strong>Research Opportunities:</strong>
                    <span> Students can engage in research projects under the guidance of faculty members.</span>
                </li>
                <li>
                    <strong>Collaboration with Industry:</strong>
                    <span> Partnerships with local industries provide students with real-world applications of their studies.</span>
                </li>
            </ul>
        </div>
    );
};

export default ChemistryLab;
