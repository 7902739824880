import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaEnvelope, FaPhoneAlt, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import './Footer.css'; // Custom styles if necessary

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={4}>
            <h5>Kalavati Institute of Management & Pharmacy</h5>
            <p>Empowering future leaders with quality education.</p>
          </Col>

          <Col md={4}>
            <h5>Contact Us</h5>
            <ul className="list-unstyled">
              <li>
                <FaEnvelope className="icon" /> info@kalavaticollege.in
              </li>
              <li>
                <FaPhoneAlt className="icon" /> 9975596764, 9673667777
              </li>
            </ul>
          </Col>

          <Col md={4}>
            <h5>Follow Us</h5>
            <ul className="social-icons list-unstyled">
              <li>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            <p>&copy; 2024-25 kalavaticollege.in. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
