import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import ConsultingImg from '../../images/consulting.png'; // Path to your consulting image
import './Consulting.css'; // For custom styles

const Consulting = () => {
  return (
    <Container className="service-container">
      <Row className="align-items-center">
        <Col md={6}>
          <Image src={ConsultingImg} alt="Consulting Service" fluid className="service-img" />
        </Col>
        <Col md={6}>
          <h2>Consulting Services</h2>
          <p>
            Our consulting services are designed to assist you in finding the best strategies for academic and operational excellence. 
            We focus on areas such as curriculum development, management processes, and operational improvement. Our team of experts 
            works closely with educational institutions and organizations to tailor solutions that meet their specific needs.
          </p>
          <h4>What We Offer:</h4>
          <ul>
            <li>Academic Curriculum Development</li>
            <li>Management Strategy Consulting</li>
            <li>Operational Efficiency Optimization</li>
            <li>Organizational Development</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Consulting;
