import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './BBA.css'; // Custom styles if needed
import BBA1 from '../../images/classroom.png';
import BBA2 from '../../images/library2.jpeg';
import BBA3 from '../../images/office1.png';

const BBA = () => {
  return (
    <div className="bba-course">
      <Container>
        {/* BBA Course Title */}
        <h1 className="text-center mt-5">Bachelor of Business Administration (BBA)</h1>
        <p className="text-center mb-5">Empowering future business leaders</p>

        {/* Course Description Section */}
        <section className="course-description">
          <h2>About the BBA Program</h2>
          <p>
            The Bachelor of Business Administration (BBA) program at Kalavati Institute of Management is designed to provide students with a solid foundation in business principles. 
            Our curriculum emphasizes critical thinking, problem-solving, and leadership skills, preparing students for diverse career opportunities in the business world.
          </p>
          <p>
            With a mix of theoretical knowledge and practical applications, students will learn about key areas such as marketing, finance, human resources, and operations management. 
            Our experienced faculty guides students through real-world case studies and projects, ensuring they graduate with the skills needed to succeed.
          </p>
        </section>

        {/* Faculty Section */}
        <section className="faculty mt-5">
          <h2>Meet Our Faculty</h2>
          <Row className="mt-4">
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/150?text=Faculty+1"
                alt="Faculty 1"
                className="img-fluid faculty-photo"
              />
              <h3>Dr. John Doe</h3>
              <p>Head of BBA Department</p>
            </Col>
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/150?text=Faculty+2"
                alt="Faculty 2"
                className="img-fluid faculty-photo"
              />
              <h3>Prof. Jane Smith</h3>
              <p>Senior Lecturer</p>
            </Col>
            <Col md={4} className="text-center">
              <img
                src="https://via.placeholder.com/150?text=Faculty+3"
                alt="Faculty 3"
                className="img-fluid faculty-photo"
              />
              <h3>Dr. Emily Johnson</h3>
              <p>Associate Professor</p>
            </Col>
          </Row>
        </section>

        {/* Facilities Section */}
        <section className="facilities mt-5">
          <h2>Facilities</h2>
          <p>
            Our institute provides state-of-the-art facilities to enhance the learning experience of our students. 
            We have well-equipped classrooms, a modern library, and dedicated spaces for group work and projects.
          </p>
          <Row className="mt-4">
            <Col md={4} className="text-center">
              <img
src={BBA1}
alt="Classroom"
                className="img-fluid facility-photo"
              />
              <h4>Classroom</h4>
            </Col>
            <Col md={4} className="text-center">
              <img
src={BBA2}
alt="Library"
                className="img-fluid facility-photo"
              />
              <h4>Library</h4>
            </Col>
            <Col md={4} className="text-center">
              <img
src={BBA3}
alt="Office"
                className="img-fluid facility-photo"
              />
              <h4>Office</h4>
            </Col>
          </Row>
        </section>

        {/* HOD Section */}
        <section className="hod mt-5">
          <h2>Head of Department</h2>
          <Row className="mt-4">
            <Col className="text-center">
              <img
                src="https://via.placeholder.com/200?text=HOD"
                alt="HOD"
                className="img-fluid hod-photo"
              />
              <h3>Dr. John Doe</h3>
              <p>Head of BBA Department</p>
              <p>
                Dr. John Doe is a distinguished academic with extensive experience in business education. 
                His passion for teaching and commitment to student success make him a vital part of our faculty.
              </p>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default BBA;
