import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import './Home.css'; // Custom styles if needed
import Home1 from '../images/home10.png';
import Home2 from '../images/home6.png';
import Home3 from '../images/home7.png';
import Home4 from '../images/home8.png';
import Home5 from '../images/bba6.png';
import Home6 from '../images/bcaa.png';
import Home7 from '../images/pharmacy2.webp';
import Principal from '../images/principal.jpg';
import College from '../images/college.png';

const Home = () => {
  return (
    <div className="home">
      <Container>
        {/* Carousel Component */}
        <Carousel interval={3000} pause="hover">
          <Carousel.Item>
            <img className="d-block w-100" src={Home1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={Home2} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={Home3} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={Home4} alt="Fourth slide" />
          </Carousel.Item>
        </Carousel>

        {/* College Details Section */}
        <section className="college-details">
          <h2 className="text-center mt-5">Welcome to Kalavati Institute of Management</h2>
          <p className="text-center">Your journey to excellence starts here.</p>

          <Row className="mt-4 justify-content-center">
            <Col md={6} className="text-center">
              <img src={College} alt="Kalavati Institute of Management" className="img-fluid college-photo" />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <p className="text-center">
                Kalavati Institute of Management is a premier institution offering the best in business education.
                Our mission is to cultivate leaders who make a positive impact in the world.
              </p>
              <p className="text-center">
                We offer a variety of courses and programs tailored to meet the evolving needs of industries.
                Join us to start your journey toward a successful career and bright future.
              </p>
            </Col>
          </Row>
        </section>

        {/* Our Course Program Section */}
        <section className="course-program mt-5">
          <h2 className="text-center mb-4">Our Course Program</h2>
          <Row>
            <Col md={6} className="text-center">
              <div className="course-box">
                <img src={Home5} alt="BBA Program" height="500px" className="img-fluid course-photo" />
                <h3 className="mt-3">BBA - Bachelor of Business Administration</h3>
                <p>
                  Our BBA program equips students with critical management skills, business knowledge, and leadership abilities to thrive in today’s competitive business world.
                  With a blend of theoretical and practical learning, graduates will be prepared for dynamic business environments.
                </p>
              </div>
            </Col>

            <Col md={6} className="text-center">
              <div className="course-box">
                <img src={Home6} alt="BCA Program" className="img-fluid course-photo" />
                <h3 className="mt-3">BCA - Bachelor of Computer Applications</h3>
                <p>
                  Our BCA program provides in-depth knowledge in computer applications, programming languages, and modern technology trends.
                  It prepares students for careers in software development, system management, and IT infrastructure management.
                </p>
              </div>
            </Col>

            <Col md={12} className="text-center">
              <div className="course-box">
                <img src={Home7} alt="Pharmacy Program" className="img-fluid course-photo" />
                <h3 className="mt-3">D.Pharmacy</h3>
                <p>
                  Our D.Pharmacy program offers comprehensive training in pharmaceuticals, including drug formulation and patient care practices.
                  Graduates will be well-prepared for careers in community pharmacies, hospitals, and pharmaceutical companies.
                </p>
              </div>
            </Col>
          </Row>
        </section>

        {/* Message from Principal Section */}
        <section className="message-from-principal mt-5">
          <h2 className="text-center">Message from Director</h2>
          <Row className="mt-4 justify-content-center">
            <Col md={6} className="text-center">
              <img src={Principal} alt="Principal" className="img-fluid principal-photo" />
              <p className="mt-3">
                Focus on your goal. Don't look in any direction but ahead.
              </p>
              <p className="text-justify">
                <strong>From The Desk of Director - Words to the parents...</strong><br />
                I have immense pleasure to observe the growth of each and every child in the institution. Each and every child is being given training in various fields by competent teachers with an abundance of resources to ensure overall growth of everyone's personality.
              </p>
              <p className="text-justify">
                The students are learning in a happy atmosphere under the guidance of renowned educationists and latest teaching methodology.
              </p>
              <p className="text-justify">
                Proper steps have been taken for ingraining discipline, moral, and social values along with all the facilities mentioned above and many more in practice.
              </p>
              <p className="text-justify">
                I am sure each and every student will bloom like flowers and spread their fragrance everywhere. After all, your children's future is our aim...! <br />
                <strong>Director</strong>
              </p>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default Home;
