import React from 'react';

const Courses = () => {
  return (
    <div className="courses">
      <h2>Courses Offered</h2>
      <ul>
        <li>BBA</li>
        <li>BCA</li>
        <li>MBA</li>
      </ul>
    </div>
  );
};

export default Courses;
